<!-- 乡贤详情页面 -->
<template>
  <div>
    <el-card class="box-card">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item><a @click="request">首页</a></el-breadcrumb-item>
        <el-breadcrumb-item>乡贤管理</el-breadcrumb-item>
        <el-breadcrumb-item>详情</el-breadcrumb-item>
      </el-breadcrumb>
      <div
        v-text="townshipname"
        style="font-size: 30px;font-weight: bold;"
      ></div>
      <div class="hr-20"></div>
      <el-table :data="tableData" :stripe="false">
        <el-table-column
          type="index"
          label="序号"
          width="50"
          align="center"
        ></el-table-column>
        <el-table-column label="姓名" width="80" align="center">
          <template scope="scope">
            <span v-if="scope.row.status === 110" style="color: red">{{
              scope.row.name
            }}</span>
            <span v-else>{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column label="性别" width="50" align="center">
          <template slot-scope="scope">
            <span
              v-if="scope.row.status === 110"
              style="color: red"
              v-html="scope.row.sex == '0' ? '女' : '男'"
            ></span>
            <span v-else v-html="scope.row.sex == '0' ? '女' : '男'"></span>
          </template>
        </el-table-column>
        <el-table-column label="出生年月" width="100" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.status === 110" style="color: red">{{
              scope.row.birthday | formatDate
            }}</span>
            <span v-else>{{ scope.row.birthday | formatDate }}</span>
          </template>
        </el-table-column>
        <el-table-column label="学历学位" width="100" align="center">
          <template scope="scope">
            <span v-if="scope.row.status === 110" style="color: red">{{
              scope.row.academic
            }}</span>
            <span v-else>{{ scope.row.academic }}</span>
          </template>
        </el-table-column>
        <el-table-column label="现工作单位及职务" width="150" align="center">
          <template scope="scope">
            <span v-if="scope.row.status === 110" style="color: red">{{
              scope.row.occupation
            }}</span>
            <span v-else>{{ scope.row.occupation }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="获得的最高荣誉称号或入选人才 工作情况"
          width="150"
          align="center"
          min-width="320px"
          show-overflow-tooltip
        >
          <template scope="scope">
            <span v-if="scope.row.status === 110" style="color: red">{{
              scope.row.honours
            }}</span>
            <span v-else>{{ scope.row.honours }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="联系方式（手机、固定电话）"
          width="150"
          align="center"
        >
          <template scope="scope">
            <span v-if="scope.row.status === 110" style="color: red">{{
              scope.row.contact
            }}</span>
            <span v-else>{{ scope.row.contact }}</span>
          </template>
        </el-table-column>
        <el-table-column label="家庭情况及联系方式" width="450">
          <el-table-column
            label="在赣州主要亲属（或者朋友）姓名"
            width="150"
            align="center"
          >
            <template scope="scope">
              <span v-if="scope.row.status === 110" style="color: red">{{
                scope.row.familyName
              }}</span>
              <span v-else>{{ scope.row.familyName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="地级市" width="150" align="center">
            <template scope="scope">
              <span v-if="scope.row.status === 110" style="color: red">{{
                scope.row.city
              }}</span>
              <span v-else>{{ scope.row.city }}</span>
            </template>
          </el-table-column>
          <el-table-column label="单位或住址" width="150" align="center">
            <template scope="scope">
              <span v-if="scope.row.status === 110" style="color: red">{{
                scope.row.familyCompany
              }}</span>
              <span v-else>{{ scope.row.familyCompany }}</span>
            </template>
          </el-table-column>
          <el-table-column label="联系电话" width="150" align="center">
            <template scope="scope">
              <span v-if="scope.row.status === 110" style="color: red">{{
                scope.row.familyContact
              }}</span>
              <span v-else>{{ scope.row.familyContact }}</span>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column label="人才类型" width="150" align="center">
          <template scope="scope">
            <span v-if="scope.row.status === 110" style="color: red">{{
              scope.row.type
            }}</span>
            <span v-else>{{ scope.row.type }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="所在乡（镇、街道、管理处）"
          width="150"
          align="center"
        >
          <template scope="scope">
            <span v-if="scope.row.status === 110" style="color: red">{{
              scope.row.township
            }}</span>
            <span v-else>{{ scope.row.township }}</span>
          </template>
        </el-table-column>
        <el-table-column label="所在区（社区）" width="150" align="center">
          <template scope="scope">
            <span v-if="scope.row.status === 110" style="color: red">{{
              scope.row.village
            }}</span>
            <span v-else>{{ scope.row.village }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="100" align="center">
          <template scope="scope">
            <span v-if="scope.row.status === 110" style="color: red"
              ><el-button
                size="mini"
                type="success"
                icon="el-icon-circle-check"
                @click="agree(scope.row)"
                >确认</el-button
              ></span
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="prev, pager, next"
        @current-change="currentchange"
        :total="total"
      >
      </el-pagination>
      <div class="hr-10"></div>
      <el-alert
        v-show="applystatus == 1"
        title="待审核"
        type="warning"
      ></el-alert>
      <el-alert
        v-show="applystatus == 2"
        title="审核成功"
        type="success"
      ></el-alert>
      <el-row>
        <div class="hr-10"></div>
        <span v-show="applystatus == 1" style="padding: 0 8px;">
          <el-button type="success" @click="handle(2)">同意修改</el-button>
          <el-button type="danger" @click="handle(3)">驳回修改请求</el-button>
        </span>
        <el-button @click="prev">返回上一级</el-button>
        <el-button type="warning" @click="Download">导出Excel</el-button>
      </el-row>
    </el-card>
  </div>
</template>

<script>
//import headtitle from "@/components/ControlPanel/headtitle";
export default {
  components: {
    //headtitle
  },
  data() {
    return {
      id: this.$route.params.id,
      pages: 0,
      pageSize: 10,
      currtow: 1,
      total: 1,
      townshipname: "",
      applystatus: 0,
      tableData: [],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    request() {
      let newPage = this.$router.resolve({
        name: "EntryStoma",
      });
      window.open(newPage.href, "_blank");
    },
    getList(page = 1) {
      const self = this;
      this.$axios
        .get("/worthy/page", {
          params: {
            current: page,
            creator: self.id,
            size: 8,
          },
        })
        .then(function(res) {
          console.log(res);
          self.total = res.data.data.total;
          self.tableData = res.data.data.records;
          self.getTyle();
        });
    },
    getTyle() {
      const self = this;
      this.$axios
        .get("/worthy/manager", {
          params: {
            id: self.id,
          },
        })
        .then(function(res) {
          console.log(res.data.data.township);
          self.townshipname = res.data.data.township;
          self.applystatus = res.data.data.apply.status;
        });
    },
    //分页
    currentchange(page) {
      this.getList(page);
    },
    /**
     * 审批
     * @param num number
     */
    handle(num) {
      const self = this;
      this.$confirm("确定此操作吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios
            .put("/worthy/apply/option?ownerId=" + this.id + "&status=" + num)
            .then(function(res) {
              console.log(res.data.status == 200);
              if (res.data.status == 200) {
                self.getList();
              }
            });
        })
        .catch(() => {
          self.$notify({
            title: "成功取消",
            message: "取消审核！",
            type: "success",
          });
        });
    },

    /**
     * 确认查看 并且修改状态
     * @param row
     */
    agree(row) {
      const self = this;
      let newform = row;
      newform.status = 0;
      this.$axios.put("/worthy", newform).then(function(res) {
        if (res.data.status == 200) {
          self.getList();
          self.$root.success("完成");
        }
      });
    },
    //附件下载
    Download() {
      const self = this;
      this.$axios
        .get("/worthy/export/excel?creator=" + self.id, {
          responseType: "blob", // 设置响应数据类型
        })
        .then(function(res) {
          console.log(res);
          if (res.status == 200) {
            let url = window.URL.createObjectURL(new Blob([res.data]));
            let link = document.createElement("a");
            link.style.display = "none";
            link.href = url;
            link.setAttribute("download", self.townshipname + ".xlsx");
            document.body.appendChild(link);
            link.click();
          }
        });
    },
    prev() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less" scoped></style>
